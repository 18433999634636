.ctcrs {
    width: 0px !important;
}

@keyframes scale {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.animation-no-data {
    animation: scale 0.5s ease-in-out 1 forwards;
}