@tailwind base;
@tailwind components;
@tailwind utilities;


.fc-button {
    font-size: 14px !important;
    background-color: #319795 !important;
    font-weight: bold !important;
    border: none !important;
}

.fc-toolbar-title {
    font-size: 17px !important;
}

.fc-col-header-cell-cushion .fc-daygrid-day-number {
    font-size: 13px !important;
}

.fc-daygrid-day-number {
    font-size: 14px !important;
}

.fc-scrollgrid-section-header {
    background-color: #f9fafb !important;
}

@keyframes form-animation {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes item-animation {
    0% {
        height: 0% !important;
    }

    50% {
        opacity: 50% !important
    }

    100% {
        opacity: 100 !important
    }
}

.animation-form {
    animation: form-animation 0.3s ease-in-out;
}

.animation-form-l2 {
    animation: form-animation 0.4s ease-in-out;
}

.animation-form-l3 {
    animation: form-animation 0.5s ease-in-out;
}

.animation-item {
    animation: form-animation 0.2s ease-in-out;
}

.item-animation {
    animation: item-animation 0.2s ease-in-out;
}


@layer components {
	.profile-wrapper {
		@apply flex justify-center items-center border rounded-md p-4 lg:px-8 xl:px-12 2xl:px-16 relative bg-gradient-to-bl from-slate-100 shadow-sm to-slate-50
	}

	.form-wrapper {
		@apply md:col-span-2 lg:col-span-2 xl:col-span-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 p-3 xl:grid-cols-3 gap-3 border rounded-md
	}

	.info-container {
		@apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3
	}

    .two-col-container {
		@apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-3
	}

    .one-col-container {
		@apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-3
	}
}